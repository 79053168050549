@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../public/fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../public/fonts/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../public/fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: 600;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}


.white {
    color: white !important
}

.black {
  color: black !important
}

.fullWidth {
  width: 100%;
}

.bold {
    font-weight: bold !important;
}

.word-wrap {
    word-wrap: anywhere;
}

.unstyle-anchors  a  {
    color: inherit;
    text-decoration: none;
}

.fade-in {
  animation: fadeInAnimation ease 1.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
      transform: translateY(30px);
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
   }
}
